import React from 'react'

import { useFormData } from '../../../hooks/useFormData'
import CustomForm from '../../CustomForm/CustomForm'
import { CarStateContext } from '../../../layouts/PageLayout/PageLayout'

import { BlockFormSection } from './BlockForm.styled'

const BlockForm = data => {
	const formData = useFormData(data.formId)

	return (
		<CarStateContext.Consumer>
			{context => {
				return (
					<BlockFormSection paddingBottom={data.paddingBottom} paddingTop={data.paddingTop}>
						<CustomForm carData={context} formId={data.formId} formData={formData} />
					</BlockFormSection>
				)
			}}
		</CarStateContext.Consumer>
	)
}

export default BlockForm

import styled from 'styled-components'

import Section from '../../Section'

const BlockFormSection = styled(Section)`
	position: relative;
	display: flex;
	padding-top: ${props => props.paddingTop}px;
	padding-bottom: ${props => props.paddingBottom}px;
`

export { BlockFormSection }

import { useStaticQuery, graphql } from 'gatsby'

export const useFormData = id => {
	const data = useStaticQuery(
		graphql`
			query {
				allForm {
					nodes {
						formId
						properties {
							form {
								fields {
									type
									name
									basetype
									content
									labels
									options
								}
							}
						}
					}
				}
			}
		`
	)

	let formFields = {}
	data.allForm.nodes.map(formObject => {
		if (formObject.formId === id) formFields = formObject.properties.form.fields
	})
	return formFields
}
